<!--
 * @Author: your name
 * @Date: 2021-03-13 11:08:52
 * @LastEditTime: 2024-01-18 10:04:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\user\register.vue
-->
<template>
  <div>
    <patientSelect @onConfirm="patientConfirm" />
    <load-more :data-source="dataList" :total="total" :loading="loading" @load-more="onLoad">
      <appointment-item :data="dataList" type="2" @refresh="refresh" />
    </load-more>
  </div>
</template>
<script>
import { userModel } from '@/api/user'
import loadMore from '@/assets/js/mixin/load-more'
export default {
  name:'Register',//挂号
  mixins:[loadMore],
  data (){
    return{
      Picker:false,
      patientIdCard:'',
      dataList:[]
    }
  },
  methods:{
    getList(){
      let params={
        "zjhm":this.patientIdCard,
        "flag":"2",
        page: this.page,
        pageSize: this.pageSize
      }
      userModel.appointmentList(params).then(res=>{
          this.dataList.push(...(res.data || []))
          this.total = res.count || 0
          this.loading = false

      })
    },
    patientConfirm(value){
      this.patientIdCard=value.zjh||""
      this.page=1
      this.dataList=[]
      this.getList()
    },
    refresh(){
      this.dataList = []
      let params={
        "zjhm":this.patientIdCard,
        "flag":"2",
        page: '1',
        pageSize: this.pageSize
      }
      userModel.appointmentList(params).then(res=>{
          this.dataList.push(...(res.data || []))
          this.total = res.count || 0
          this.loading = false

      })
    },
    onLoad(){
      this.loading=true
      this.page+=1
      this.getList()
    }

  }

}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
.page-box{
  padding: 0 30px;
}
</style>
